<template>
    <div>
        <div class="d-flex align-center">
            <v-text-field v-if="!config.type || config.type === 'text'" :placeholder="config.placeholder" :label="config.label"
                          v-model="search"
                          :maxlength="config.maxlength"
                          :dense="config.dense" :required="config.required" :rules="config.rules">
            </v-text-field>
            <v-combobox ref="combo" v-if="config.type === 'autocomplete'"
                        v-model="searchItem"
                        :search-input.sync="search"
                        :items="config.items"
                        :label="config.label"
                        :item-text="config.itemText"
                        :item-value="config.itemValue"
                        :placeholder="config.placeholder" :required="config.required" :rules="config.rules"
            ></v-combobox>
            <v-btn small class="ml-4" color="primary" @click="add">
                <v-icon>mdi-plus</v-icon>
            </v-btn>
        </div>
        <v-card tile>
            <template v-for="(item, i) in model[config.key]">
                <v-divider v-if="i !== 0" :key="'divider_' + i"></v-divider>
                <v-list-item :key="i">
                    <v-list-item-content>
                        <v-list-item-title v-text="item.value"></v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-btn icon @click="remove(item)">
                            <v-icon>mdi-delete</v-icon>
                        </v-btn>
                    </v-list-item-action>
                </v-list-item>
            </template>
        </v-card>
    </div>
</template>

<script>

import {v4 as uuidv4} from 'uuid';

export default {
    name: "FormAddText",
    data() {
        return {
            search: null,
            searchItem: null
        }
    },
    watch: {
        search(val) {
            if (this.config.type && this.config.type === 'autocomplete') {
                val && this.config.search(val)
            }
        }
    },
    props: ['config', 'model', 'errors'],
    methods: {

        add() {
            if (!this.model[this.config.key]) {
                this.model[this.config.key] = [];
            }
            if (this.search && !this.model[this.config.key].some(l => l.value === this.search)) {
                if (!this.config.items) {
                    this.config.items = [];
                }
                let item = this.config.items.filter(i => i[this.config.itemText] === this.search);
                let id = uuidv4();
                let isNew = true;
                if (item && item.length > 0) {
                    id = item[0][this.config.itemValue];
                    isNew = false;
                }
                this.model[this.config.key].push({id: id, value: this.search, new: isNew});
                this.$forceUpdate();
                this.$refs.combo.reset();
            }
            this.search = null;
        },
        remove(item) {
            this.model[this.config.key] = this.model[this.config.key].filter(i => i.id !== item.id);
            this.$forceUpdate();
        }
    }
}
</script>

